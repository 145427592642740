import SparkIconXL from "../svgs/SparkIconXL";

type MobileLoadingSpinnerProps = {
  text?: String;
};

const MobileLoadingSpinner = ({ text }: MobileLoadingSpinnerProps) => {
  return (
    <div className="flex flex-col bg-black fixed top-0 left-0 z-[5] overflow-scroll h-full w-full text-center">
      <span className="flex flex-col items-center h-full">
        <SparkIconXL className="fill-red animate-rotate" />
        {text ? <p className="text-2xl text-white">{text}</p> : null}
      </span>
    </div>
  );
};
export default MobileLoadingSpinner;
