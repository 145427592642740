import { createContext, useContext, useEffect, useState } from "react";

export const UIContext = createContext({
  hydrated: false,
});

export const UIContextProvider = ({ children }) => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  return (
    <UIContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        hydrated,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error("useUIContext must be used within a UIContextProvider");
  }

  return context;
};
