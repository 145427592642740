import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

type SparkContextType = {
  removingConnections: string[];
  setRemovingConnections: Dispatch<SetStateAction<string[]>>;
  removingFromCluster: string[];
  setRemovingFromCluster: Dispatch<SetStateAction<string[]>>;
};

const initialContext: SparkContextType = {
  removingConnections: [],
  setRemovingConnections: () => {},
  removingFromCluster: [],
  setRemovingFromCluster: () => {},
};

export const SparkContext = createContext<SparkContextType>(initialContext);

export const SparkContextProvider = ({ children }) => {
  const [removingConnections, setRemovingConnections] = useState([]);
  const [removingFromCluster, setRemovingFromCluster] = useState([]);

  return (
    <SparkContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        removingConnections,
        setRemovingConnections,
        removingFromCluster,
        setRemovingFromCluster,
      }}
    >
      {children}
    </SparkContext.Provider>
  );
};

export const useSparkContext = () => {
  const context = useContext(SparkContext);

  if (context === undefined) {
    throw new Error("useSparkContext must be used within a UIContextProvider");
  }

  return context;
};
