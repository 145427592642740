/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UIState {
  mobileMenuIsOpen: boolean;
  showSignupOrLogInBanner: boolean;
  headerHeight: number;
}

const initialState: UIState = {
  mobileMenuIsOpen: false,
  showSignupOrLogInBanner: false, // TODO: was also previously storing banner being dismissed in local storage to prevent banner appearing again on refresh
  headerHeight: 53, // default size to reduce jank
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMobileMenuIsOpen: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.mobileMenuIsOpen = action.payload;
    },
    toggleMobileMenuIsOpen: (state) => {
      state.mobileMenuIsOpen = !state.mobileMenuIsOpen;
    },
    setShowSignupOrLogInBanner: (state, action: PayloadAction<boolean>) => {
      state.showSignupOrLogInBanner = action.payload;
    },
    setHeaderHeight: (state, action: PayloadAction<number>) => {
      state.headerHeight = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMobileMenuIsOpen,
  toggleMobileMenuIsOpen,
  setShowSignupOrLogInBanner,
  setHeaderHeight,
} = uiSlice.actions;

export default uiSlice.reducer;
