type SparkIconProps = {
  className?: string;
};

const SparkIconXL = ({ className }: SparkIconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 20 20"
  >
    <path
      d="M16.907 2.537l-.668-.602a.358.358 0 00-.48 0l-5.237 4.733L7.658.211a.355.355 0 00-.435-.193l-.859.279a.356.356 0 00-.238.412l1.482 6.906L.58 6.866a.356.356 0 00-.386.28l-.187.88c-.038.18.067.36.242.414l6.758 2.117-4.207 5.77a.349.349 0 00.048.463l.684.615c.132.12.332.119.464 0l5.25-4.74 2.864 6.463a.35.35 0 00.427.19l.87-.283a.349.349 0 00.234-.405l-1.478-6.913 7.022.75c.18.02.345-.1.382-.277l.19-.89a.354.354 0 00-.237-.408L12.8 8.718l4.156-5.705a.357.357 0 00-.05-.476"
      className={className}
      fillRule="evenodd"
    />
  </svg>
);

export default SparkIconXL;
